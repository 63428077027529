import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '../assets/image/logo_heng_bai.png';
import _imports_1 from '../assets/image/u26.svg';
import _imports_2 from '../assets/image/av/菜单_u4.svg';
var _hoisted_1 = {
  "class": "header"
};
var _hoisted_2 = {
  "class": "nav_list"
};
var _hoisted_3 = {
  "class": "lang"
};
var _hoisted_4 = {
  "class": "el-dropdown-link"
};
var _hoisted_5 = {
  "class": "nav_list",
  style: {
    "justify-content": "space-between"
  }
};
var _hoisted_6 = {
  "class": "lang",
  style: {
    "margin-left": "0"
  }
};
var _hoisted_7 = {
  "class": "name",
  style: {
    "color": "rgba(27, 32, 43, 1)"
  }
};
var _hoisted_8 = {
  "class": "nav_list_tow"
};
var _hoisted_9 = {
  "class": "lang"
};
import { onBeforeMount, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import drawer from "./drawer.vue";
import { ArrowDownBold } from "@element-plus/icons-vue";
export default {
  __name: 'head',
  props: {
    path: ""
  },
  setup: function setup(__props) {
    var _useI18n = useI18n(),
      locale = _useI18n.locale,
      t = _useI18n.t;
    var value = ref("zh"); //选中的语种 默认中文
    var langName = ref("简体中文");
    var options = [{
      value: "zh",
      label: "简体中文"
    }
    // {
    //   value: "en",
    //   label: "English",
    // },
    ];
    //点击切换 储存语种并进行切换
    var handleChange = function handleChange(item) {
      localStorage.setItem("language", item.value);
      locale.value = item.value;
      langName.value = item.label;
    };
    var chid = ref(null);
    var handleMore = function handleMore() {
      chid.value.drawer = true;
    };
    var activeName = ref(0);
    var list = ref([{
      name: t("head.首页"),
      path: "/"
    }, {
      name: t("head.产品服务"),
      path: "/product"
    }, {
      name: t("head.技术支持"),
      path: "/technology"
    }, {
      name: t("head.演示站"),
      path: "/demonstration"
    }, {
      name: t("head.成功案例"),
      path: "/case"
    }, {
      name: t("head.FAQ"),
      path: "/FAQ"
    }, {
      name: t("head.联系我们"),
      path: "/about"
    }]);
    var router = useRouter();
    watch(function () {
      return activeName.value;
    }, function (val, old) {
      router.push({
        path: list.value[activeName.value].path
      });
      window.scrollTo(0, 0);
    });
    var props = __props;
    watch(function () {
      return props.path;
    }, function (val, old) {
      list.value.map(function (item, index) {
        if (item.path == val) {
          activeName.value = index;
        }
      });
    });
    onMounted(function () {
      window.addEventListener('scroll', handleScroll, true);
    });
    var top = ref(0);
    var handleScroll = function handleScroll() {
      top.value = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset);
      console.log(top.value);
    };
    return function (_ctx, _cache) {
      var _component_el_tab_pane = _resolveComponent("el-tab-pane");
      var _component_el_tabs = _resolveComponent("el-tabs");
      var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      var _component_el_dropdown = _resolveComponent("el-dropdown");
      var _component_el_icon = _resolveComponent("el-icon");
      return _openBlock(), _createElementBlock("div", {
        "class": _normalizeClass(activeName.value == 4 || top.value != 0 ? 'ug-head ug-head-s' : 'ug-head')
      }, [_createElementVNode("div", _hoisted_1, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        "class": "logo"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tabs, {
        modelValue: activeName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return activeName.value = $event;
        }),
        "class": "demo-tabs",
        onTabClick: _ctx.handleClick
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, function (item, index) {
            return _openBlock(), _createBlock(_component_el_tab_pane, {
              label: item.name,
              name: index,
              key: index
            }, null, 8, ["label", "name"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["modelValue", "onTabClick"]), _createElementVNode("div", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        "class": "lang-img"
      }, [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })], -1)), _createVNode(_component_el_dropdown, {
        style: {
          "margin-left": "20px",
          "color": "#fff"
        }
      }, {
        dropdown: _withCtx(function () {
          return [_createVNode(_component_el_dropdown_menu, null, {
            "default": _withCtx(function () {
              return [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(options, function (item, index) {
                return _createVNode(_component_el_dropdown_item, {
                  key: index,
                  onClick: function onClick($event) {
                    return handleChange(item);
                  }
                }, {
                  "default": _withCtx(function () {
                    return [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_cache[1] || (_cache[1] = _createElementVNode("div", {
                      "class": "lang-img"
                    }, [_createElementVNode("img", {
                      src: _imports_1,
                      alt: ""
                    })], -1)), _createElementVNode("div", _hoisted_7, _toDisplayString(item.label), 1)])])];
                  }),
                  _: 2
                }, 1032, ["onClick"]);
              }), 64))];
            }),
            _: 1
          })];
        }),
        "default": _withCtx(function () {
          return [_createElementVNode("span", _hoisted_4, _toDisplayString(langName.value), 1)];
        }),
        _: 1
      })])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_el_icon, null, {
        "default": _withCtx(function () {
          return [_createVNode(_unref(ArrowDownBold))];
        }),
        _: 1
      }), _cache[3] || (_cache[3] = _createElementVNode("div", {
        "class": "name"
      }, "CN", -1))]), _createElementVNode("div", {
        "class": "more",
        onClick: handleMore
      }, _cache[4] || (_cache[4] = [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)]))])]), _createVNode(drawer, {
        ref_key: "chid",
        ref: chid
      }, null, 512)], 2);
    };
  }
};