//index.ts
import { createI18n } from "vue-i18n";
import en from './locales/en'
import zh from './locales/zh';
 
const i18n = createI18n({
  locale:localStorage.getItem('language') || 'zh', // 默认是中文
//   fallbackLocale: 'en', // 语言切换的时候是英文
  globalInjection:true,//全局配置$t
  legacy:false,//vue3写法
  messages:{en,zh}// 需要做国际化的语种,就是刚才编写的两个语言
 
})
 
export default i18n