import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import directives from './utils/index';

import i18n from './i18n/index' //引入配置的语言


const app = createApp(App);
app.use(i18n)       
//注册'v-animate'  当元素出现在可视范围时添加类名触发动效样式
app.use(router);
directives(app) // 调用安装指令
app.mount('#app')
