import "core-js/modules/es.function.name.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../assets/image/footer/Container.png';
var _hoisted_1 = {
  "class": "footer"
};
var _hoisted_2 = {
  "class": "connection"
};
var _hoisted_3 = {
  "class": "lableWidth"
};
var _hoisted_4 = {
  "class": "connection-title"
};
var _hoisted_5 = {
  "class": "title"
};
var _hoisted_6 = {
  "class": "serach"
};
var _hoisted_7 = {
  "class": "connection-info"
};
var _hoisted_8 = {
  "class": "icon"
};
var _hoisted_9 = ["src"];
var _hoisted_10 = {
  "class": "name"
};
var _hoisted_11 = {
  "class": "connection",
  style: {
    "padding-top": "0px"
  }
};
var _hoisted_12 = {
  "class": "lableWidth avUrl"
};
var _hoisted_13 = {
  "class": "tips"
};
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
export default {
  __name: 'footer',
  setup: function setup(__props) {
    var _useI18n = useI18n(),
      t = _useI18n.t;
    var listInfo = ref([{
      icon: require("../assets/image/footer/feiji_off_20.png"),
      name: "@abonsn"
    }, {
      icon: require("../assets/image/footer/feiji_off_20.png"),
      name: "@tangnade9"
    }, {
      icon: require("../assets/image/footer/feiji_off_20.png"),
      name: "@tengxin1"
    }
    // {icon:require("../assets/image/footer/youxiang_on.png"),name:"xxxxx@gmail.com"},
    // {icon:require("../assets/image/footer/whatup_off_20.png"),name:"+63 56556 9898"},
    ]);
    var avList = ref([{
      icon: require("../assets/image/av/u106.png"),
      url: "XXX-XXXX-XXXX"
    }, {
      icon: require("../assets/image/av/u107.png"),
      url: "@xxxxxxxxxxx"
    }, {
      icon: require("../assets/image/av/u105.png"),
      url: "xxxxx@gmail.com"
    }, {
      icon: require("../assets/image/av/u108.png"),
      url: "+63 56556 9898"
    }, {
      icon: require("../assets/image/av/u109.png"),
      url: "+63 56556 9898"
    }, {
      icon: require("../assets/image/av/u110.png"),
      url: "+63 56556 9898"
    }, {
      icon: require("../assets/image/av/u111.png"),
      url: "+63 56556 9898"
    }]);
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('footer.联系我们')), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString(_unref(t)('footer.UG包网')), 1), _cache[0] || (_cache[0] = _createElementVNode("div", {
        "class": "google"
      }, "搜索", -1))])]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listInfo.value, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          "class": "item",
          key: index
        }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("img", {
          src: item.icon,
          alt: ""
        }, null, 8, _hoisted_9)]), _createElementVNode("div", _hoisted_10, _toDisplayString(item.name), 1)]);
      }), 128))])])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_13, [_createElementVNode("span", null, "© 2020-2024 " + _toDisplayString(_unref(t)('footer.版权')), 1)])])]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        "class": "connection c",
        style: {
          "border-bottom": "none"
        }
      }, [_createElementVNode("div", {
        "class": "lableWidth avUrl"
      })], -1))]);
    };
  }
};