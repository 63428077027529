//en
//zh
export default {
    head: {
        '首页': '首页',
        '产品服务': '产品服务',
        '技术支持': '技术支持',
        '演示站': '演示站',
        '成功案例': '成功案例',
        'FAQ': 'FAQ',
        '联系我们':'联系我们',
    },
    home:{
        'UG包网':"UG包网",
        'TG号码':"TG号码",
        "与我们一起开始":"与我们一起开始",
        "您的事业":"您的事业",
        "选择我们":"选择我们，开启独具匠心的一体式包网定制体验。",
        "联系我们":"联系我们",
        "关于我们":"关于我们",
        "关于我们详情":"UG娱乐作为经验丰富的老牌包网品牌专业赌场API供应商，我们为您的业务目的提供最合适的解决方案。我们专注于提供全方位的包网平台搭建服务，作为行业的佼佼者，我们的游戏平台涵盖电子游戏、真人娱乐场、棋牌、电竞、彩票、体育博彩和捕鱼七大核心领域，旨在满足各种玩家的需求，无论他们是寻求刺激的冒险者，还是喜爱策略和技巧的游戏爱好者。",
        "了解更多":"了解更多",
        "一站式定制游戏平台":"  一站式定制游戏平台，随心游戏组合，风格自选。",
        "自由定制":"自由定制",
        "自由定制详情":"UG包网提供来自 110 多家供应商的 11,700 多种赌场游戏解决方案，包括完整的网站开发和简单的集成。通过老虎机、真人娱乐场、体育、捕鱼游戏和彩票、电竞投注、棋牌游戏等垂直领域，我们提供丰富的内容来吸引各类玩家。",
        "UG包网的特色":"UG包网的特色",
        "UG包网的特色详情":"UG专注于为新入行的合作伙伴打造一站式的技术指导与服务，极大降低了他们在技术开发和企业建设上的经济压力。我们的专业之处在于提供一个度身打造的包网服务项目，专注于每个客户的独特需求！",
        "超低开版费":"超低开版费，即享灵活包网方案，轻松开启您的专属游戏平台",
        "超低开版费用":"超低开版费用",
        "超低开版费详情":"全网，超低价开版费用，无需买分，无需押金，价格透明，支付即用的服务，让您在激烈的市场竞争中脱颖而出。无论是选择经典模板还是打造独特风格，我们都能助您迅速上线，直击目标用户。无需繁琐步骤，轻松开启专属平台，迈向成功的新高度。",
        "适用于小型团队":"适用于小型团队",
        "UG包网经典开版":"UG包网经典开版",
        "免费开版":"免费开版+每月维护费用3W",
        "立即免费开版":"立即免费开版",
        "经典版享受":"经典版享受",
        "专业团队技术配置指导开站事宜":"专业团队技术配置指导开站事宜",
        "维运团队专案部署":"维运团队专案部署,网域解析等",
        "免费对接三方支付":"免费对接三方支付、简讯平台等",
        "适用于大型公司":"适用于大型公司",
        "UG包网定制开版":"UG包网定制开版",
        "开版费用3W":"开版费用3W+每月维护费用3W",
        "立即畅享定制体验":"立即畅享定制体验",
        "除经典版的所有功能":"除经典版的所有功能，定制版特享",
        "高级UI设计师协助处理页面展示效果":"高级UI设计师协助处理页面展示效果",
        "免费版仅可使用一套固定UI风格模板":"免费版仅可使用一套固定UI风格模板",
        "专业技术团队高效快速部署开站":"专业技术团队高效快速部署开站",
        "维运团队专案部署":"维运团队专案部署,网域解析等",
        "月维护费":"月维护费",
        "免费版与定制版每月支付相同维护费":"免费版与定制版每月支付相同维护费",
        "商务部":"最终洽谈后如期交付原生 APP、电脑端、H5端 (其余定制化平台或需求请及时联系商务部)",
        "​小时线上客服协助处理问题":"​7*24小时线上客服协助处理问题",
        "​技术团队高效积极协助处理解答疑惑":"​​技术团队高效积极协助处理解答疑惑",
        "​平台自订简单需求":"​​平台自订简单需求,免费开发维护",
        "​网站安全":"​​运维团队24小时确保网站安全,免费开发维护",
        "跨平台应用":"跨平台应用",
        "跨平台应用详情":"跨平台应用，全面覆盖H5、PC、安卓、iOS，让您的游戏平台无缝衔接各类设备。无论用户身处何地，都能随时随地畅享优质体验。我们致力于为您打造灵活的多端解决方案，确保每位用户都能感受到流畅一致的操作体验，从而提升平台的整体竞争力。",
        "顶尖游戏供应商":"顶尖游戏供应商",
        "顶尖游戏供应商详情":"顶尖游戏供应商，汇聚全球优质游戏内容，为您的平台注入无限活力。我们合作的供应商涵盖各大热门领域，从经典博彩到创新电子游艺，确保为玩家提供丰富多样的选择。借助这些行业领先的资源，您的平台不仅能满足多元化的用户需求，还能持续吸引和留住更多忠实玩家，助力业务长远发展。",
        "多种存取款渠道":"多种存取款渠道",
        "多种存取款渠道详情":"各式支付收款方式，让玩家充提不受限，让您的资金流转更加便捷。我们为您提供多渠道的安全支付解决方案，确保用户交易顺畅无忧。无论何时何地，轻松管理资金，畅享高效运营带来的无限可能。",
        "先进的风险管理":"先进的风险管理",
        "先进的风险管理详情":"先进的风险管理，为您的平台保驾护航。通过智能算法与实时监控，我们能快速识别并应对潜在风险，确保交易安全与平台稳定。无论是防范欺诈行为，还是优化用户体验，我们的系统都能为您提供全面的保障，让您专注于业务增长，放心迈向更大的成功。",
        "高效流畅的后台":"高效流畅的后台",
        "高效流畅的后台详情":"高效流畅的后台，让您的平台运营更加轻松便捷。凭借直观的界面设计和强大的管理工具，您可以实时监控数据、调整设置，并快速响应用户需求。一切操作都简单明了，助您高效管理各项业务，实现稳步增长。",
        "精准营销解决方案":"精准营销解决方案",
        "精准营销解决方案详情":"以符合您的商业计划的方式定义和营销您的平台，我们提供全面的技术支持和灵活的定制服务，让您在竞争中占据优势。通过智能化的数据分析与精细化的运营策略，帮助您精准锁定目标用户，提升用户粘性与平台收益。无论是启动阶段还是成长扩展，我们都将成为您最可靠的合作伙伴，共同推动业务腾飞，实现长远发展。",
        "电子游艺":"电子游艺",
        "电子游艺详情":"汇集上百款热门电子游艺，经典老虎机、刺激的轮盘、创意十足的小游戏应有尽有，简单易上手的操作和丰厚的奖金让玩家享受无尽的娱乐体验。",
        "真人娱乐":"真人娱乐",
        "真人娱乐详情":"提供逼真且多样化的真人娱乐场景，涵盖经典的百家乐、轮盘、二十一点等游戏，客户在家中也能体验到赌场的紧张与刺激，实时互动，公平透明，尽享现场氛围。",
        "体育运动":"体育运动",
        "体育运动详情":"为超过200种体育项目提供精准的赔率和多样化的投注选项。涵盖足球联赛、篮球巅峰对决，网球大满贯等热门赛事。",
        "捕鱼游戏":"捕鱼游戏",
        "捕鱼游戏详情":"经典的棋牌类游戏一网打尽，象棋、德州扑克、麻将、斗地主等，玩法丰富多样，结合智能匹配系统全球玩家一较高下。",
        "彩票投注":"彩票投注",
        "彩票投注详情":"涵盖多种主流彩票游戏，实时更新开奖数据，提供多样化的投注选择，从传统乐透到快彩玩法，满足不同彩民的需求，畅享中奖的喜悦。",
        "电竞投注":"电竞投注",
        "电竞投注详情":"覆盖全球热门电竞赛事，精准的赔率和实时数据支持，涵盖《英雄联盟》、《Dota 2》、《CS》等热门游戏，为电竞爱好者提供全方位的投注体验。",
        "棋牌游戏":"棋牌游戏",
        "棋牌游戏详情":"经典的棋牌类游戏一网打尽，象棋、德州扑克、麻将、斗地主等，玩法丰富多样，结合智能匹配系统全球玩家一较高下。",
    
    },
    product:{
        "丰富且优质的游戏内容":"丰富且优质的游戏内容",
        "丰富且优质的游戏内容详情":"来自全球顶级供应商的游戏目录（包含体育、真人、棋牌、电游、电竞、彩票、捕鱼），为玩家提供精彩的娱乐体验",
        "响应式设计":"响应式设计",
        "响应式设计详情":"无论是PC桌面还是移动设备，都能提供流畅的用户体验",
        "高效的后台管理系统":"高效的后台管理系统",
        "高效的后台管理系统详情":"先进的大数据系统，各项运营报表清晰掌握关键数据和分析，提升运营效率",
        "先进的风险管理":"先进的风险管理",
        "先进的风险管理详情":"迅速识别并应对潜在威胁，保障业务的长期发展",
        "忠诚度和VIP等级计划":"忠诚度和VIP等级计划",
        "忠诚度和VIP等级计划详情":"通过奖励玩家，提升用户参与度发掘用户终生价值",
        "虚拟币&多种支付方式":"虚拟币&多种支付方式",
        "虚拟币&多种支付方式详情":"支持玩家使用虚拟币充提，持续接入安全高效的支付方式",
        "营销模块":"营销模块",
        "营销模块详情":"根据我们的运营经验，已集成经历多次打磨的线上拉新客户模式及营销活动",
        "反欺诈解决方案":"反欺诈解决方案",
        "反欺诈解决方案详情":"确保玩家群体的安全与公平，实施额外的安全防护措施，防刷单",
        "报告与分析":"报告与分析",
        "报告与分析详情":"利用数据分析，优化平台未来项目的战略方向",
        "UG包网软件":"UG包网软件",
        "UG娱乐的包网产品":"UG娱乐的包网产品为新老运营商提供了一个充满活力的平台。我们的软件不仅能提供全面的游戏娱乐服务，帮助提升盈利能力并吸引新玩家，还包括详细的数据分析、代理管理系统、多种支付方式以及来自 130 多家供应商的丰富内容。该解决方案可以无缝集成到您任何现有网站，或与定制平台结合，进一步提升和优化您的业务。",
        "联系我们":"联系我们，获取您的定制化方案",
        "UG包网产品特色":"UG包网产品特色",
        "UG包网产品特色详情":"UG娱乐包网软件涵盖强大功能，覆盖营销‘风险管理’财务系统‘等级计划、多种付款方式，及内容交付，助您事业更上一层楼！",
        "彰显品牌定位":"定制化产品，彰显品牌定位",
        "为什么选择我们":"为什么选择我们？",
        "为什么选择我们详情":"定制化软件产品，以灵活性和可用性为重点，我们的100人技术及美术团队将为定制化合作伙伴提供定制服务，以充分实现您想要实现的目标。打造强大的产品，包括：您想接入的游戏平台、您的平台使用什么样的UI设计等。",
        "经典模版":"经典模版",
        "定制模版":"定制模版",
        "UG包网的精选供应商":"UG包网的精选供应商",
        "UG包网产品特色":"UG包网产品特色",
        "UG包网产品特色描述":"我们精选的游戏组合均来以下行业内领先的专业游戏工作室，为玩家提供大量娱乐内容选择。无论你需要在您的平台集成任何体育、棋牌、电子、真人、彩票、电竞、捕鱼。体育平台、电游平台、彩票平台、综合平台，UG都能为您提供完美的解决方案。",
        "免费经典模板":"免费经典模板，快速上线",
        "免费经典模板详情":"选择UG包网经典天蓝色模板，72小时就能为您提供一款随时可用的在线赌场，包含综合包网PC端、H5、IOS及安卓原生APP,配备经典版本游戏内容和以及有效数据分析工具的后台。"

    },
    technology:{
        "UG包网":"UG包网，顶级防护",
        "安全无忧":"安全无忧",
        "标题详情":"UG包网为您提供顶级安全保障，独家防劫持系统覆盖多端设备，守护站点、用户及资金安全。两地三备份冗余系统，三级容灾设计，一键恢复数据。强大的CDN与防御系统抵御各类攻击，系统监控与权限管理有效防范内部风险。",
        "平台积累数据":"平台积累数据",
        "UG包网产品特色":"UG包网产品特色",
        "UG包网产品特色描述":"UG包网提供来自 289 多家供应商的 17,520 多种赌场游戏解决方案，包括完整的网站开发和简单的集成。通过老虎机、真人娱乐场、体育、捕鱼游戏和彩票、电竞投注、棋牌游戏等垂直领域，我们提供丰富的内容来吸引各类玩家。",
        "独家防御系统":"独家防御系统",
        "游戏数量":"游戏数量",
        "厂商数量":"厂商数量",
        "站点数量":"站点数量",
        "平台玩家总数":"平台玩家总数",
        "系统安全":"系统安全",
        "系统安全描述":"独家研发的防劫持系统覆盖多端设备，全面保障站点/用户/资金安全。",
        "双向认证":"双向认证",
        "双向认证描述":"接口协议，双向认证加签。",
        "备份恢复":"备份恢复",
        "备份恢复描述":"两地三备份冗余系统，三级容灾/病毒/误删/异常及故障等，一键恢复。",
        "防止篡改":"防止篡改",
        "防止篡改描述":"防IP伪造篡改，暴力爆破，DDOS攻击，XSS攻击，SQL注入。",
        "保驾护航":"保驾护航",
        "保驾护航描述":"CDN高防机为网站保驾护航。",
        "防止泄露":"防止泄露",
        "防止泄露描述":"系统日志/监控/操作/权限，防内部人员泄露追踪。",
    },
    demonstration:{
        "模板演示":"模板演示",
        "模板演示描述":"您可以选择我们的经典模板，此模板可以适用于当下行业基本能业务需求。简洁的交互，流畅的设计，仅需72小时就能打造属于您的独立在线娱乐平台，请点击立即试玩！",
        "UG经典模板展示":"UG经典模板展示",
        "UG经典模板":"UG经典模板",
        "UG经典模板描述":"您可以选择我们的经典模板，此模板可以适用于当下行业基本能业务需求。简洁的交互，流畅的设计，仅需72小时就能打造属于您的独立在线娱乐平台，请点击立即试玩！",
        "立即试玩":"UG娱乐在线 立即试玩",
        "营销模块":"营销模块",
        "营销模块描述":"根据我们的运营经验，已集成经历多次打磨的线上拉新客户模式及优惠活动",
        "优质客户服务":"优质客户服务",
        "优质客户服务描述":"我们提供7×24小时的优质客服服务，无论您身处何地、何时遇到问题，我们的专业团队都会随时为您提供帮助",
        "响应式设计":"响应式设计",
        "响应式设计描述":"支持所有设备，无论是用户在任何终端打开平台，都能获得优质体验！",
        "一站式服务":"一站式服务",
        "一站式服务描述":"无需承担庞大的技术人工成本，提供完整的一站式包网服务方案，解决任何运营上的需求。",
    },
    case:{
        "客户的成功故事":"客户的成功故事，见证我们的实力",
        "客户的成功故事第二个":"成功故事，见证实力",
        "客户的成功故事详情":"UG包网提供来自 110 多家供应商的 11,700 多种赌场游戏解决方案，包括完整的网站开发和简单的集成。通过老虎机、真人娱乐场、体育、捕鱼游戏和彩票、电竞投注、棋牌游戏等垂直领域，我们提供丰富的内容来吸引各类玩家。",
        "成功案例":"成功案例",
        "D体育":"D体育是一家新兴的体育博彩平台，由于竞争激烈，他们面临如何快速进入市场并吸引玩家的挑战。",
        "D体育描述":"D体育选择了UG包网的定制化体育博彩解决方案。UG包网为其提供了一个完整的体育平台，包括实时赔率系统、丰富的赛事数据以及强大的支付系统集成。此外，UG包网还为D体育制定了一套精准的运营策略，帮助其通过多种营销手段吸引用户，并提高了用户的留存率。在UG包网的支持下，D体育平台上线后的前三个月内，活跃用户数大幅增加，平台收入稳步上升，成功打入了市场。如今，D体育已经成为业内一流的体育博彩平台之一，用户群体覆盖全球多个国家。",
    },
    about:{
        "关于我们":"关于我们",
        "关于我们描述一":"UG娱乐作为经验丰富的老牌包网品牌专业赌场API供应商，我们为您的业务目的提供最合适的解决方案。我们专注于提供全方位的包网平台搭建服务，作为行业的佼佼者，我们的游戏平台涵盖电子游戏、真人娱乐场、棋牌、电竞、彩票、体育博彩和捕鱼七大核心领域，旨在满足各种玩家的需求，无论他们是寻求刺激的冒险者，还是喜爱策略和技巧的游戏爱好者。",
        "关于我们描述二":"提供平台运维所需的全方位技术服务，快速响应日常运营需求，一站式包网服务彻底解决行业站长在金流、技术和运营上的难题。即使是小团队，也能轻松拥有自己的博彩网站。",
        "联系我们":"联系我们",
        "联系我们描述":"如果您对我们的服务与解决方案有兴趣，或者想了解更多，可以使用下列提供的任何联系方式找到我们，期待您的咨询",
    },
    faq:{
        "UG包网软件":"FQA",
        "UG包网软件描述":"除了以下常见的包网问题，如果您还有别的疑问，可以点击此处【联系我们】页面内找到任何方式联系我们，UG商务部将竭诚为您服务！ 130多家供应商的丰富内容。该解决方案可以无缝集成到您任何现有网站，或与定制平台结合，进一步提升和优化您的业务。",
        "包网常见问题解答":"包网常见问题解答",
        "更多请点击查看":"更多请点击查看",
        "技术支持页":"技术支持页",
        // "什么是包网":"1. 什么是包网？",
        // "什么是包网描述":"包网指的是一站式的网络服务解决方案，涵盖从网站搭建、维护到支付系统、客户服务等各个方面，帮助企业或个人快速上线并运营。",
        // "UG包网提供哪些服务":"2. UG包网提供哪些服务？",
        // "UG包网提供哪些服务":"2. UG包网提供哪些服务？",
    },
    footer:{
        "联系我们":"联系我们",
        "UG包网":"UG包网",
        "版权":"UG娱乐。保留所有版权",
    }
    
}