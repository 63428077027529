import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '../assets/image/h5Logo.png';
import _imports_1 from '../assets/image/whatup_on.png';
import _imports_2 from '../assets/image/Vector.png';
var _hoisted_1 = {
  "class": "nav"
};
var _hoisted_2 = {
  "class": "head"
};
var _hoisted_3 = {
  "class": "list"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  key: 0,
  "class": "item"
};
var _hoisted_6 = {
  key: 1,
  "class": "item"
};
var _hoisted_7 = {
  "class": "name"
};
import { ref } from "vue";
import { Close } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default {
  __name: 'drawer',
  setup: function setup(__props, _ref) {
    var __expose = _ref.expose;
    var drawer = ref(false);
    var _useI18n = useI18n(),
      t = _useI18n.t;
    var list = ref([{
      name: t('head.首页'),
      path: '/'
    }, {
      name: t('head.产品服务'),
      path: '/product'
    }, {
      name: t('head.技术支持'),
      path: '/technology'
    }, {
      name: t('head.演示站'),
      path: '/demonstration'
    }, {
      name: t('head.成功案例'),
      path: '/case'
    }, {
      name: t('head.FAQ'),
      path: '/FAQ'
    }, {
      name: t('head.联系我们'),
      path: '/about'
    }]);
    var router = useRouter();
    var handleClick = function handleClick(item) {
      drawer.value = false;
      router.push({
        path: item.path
      });
    };
    __expose({
      drawer: drawer
    });
    return function (_ctx, _cache) {
      var _component_el_icon = _resolveComponent("el-icon");
      var _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_drawer, {
        modelValue: drawer.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return drawer.value = $event;
        }),
        title: "",
        "with-header": false
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[2] || (_cache[2] = _createElementVNode("img", {
            "class": "logo",
            src: _imports_0,
            alt: ""
          }, null, -1)), _createElementVNode("div", {
            "class": "colse",
            onClick: _cache[0] || (_cache[0] = function ($event) {
              return drawer.value = false;
            })
          }, [_createVNode(_component_el_icon, null, {
            "default": _withCtx(function () {
              return [_createVNode(_unref(Close))];
            }),
            _: 1
          })])]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, function (item, index) {
            return _openBlock(), _createElementBlock("div", {
              key: index,
              onClick: function onClick($event) {
                return handleClick(item);
              }
            }, [index != 6 ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(item.name), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(item.name), 1), _cache[3] || (_cache[3] = _createElementVNode("div", {
              "class": "icon"
            }, [_createElementVNode("img", {
              src: _imports_1,
              alt: ""
            }), _createElementVNode("img", {
              src: _imports_2,
              alt: ""
            })], -1))]))], 8, _hoisted_4);
          }), 128))])])];
        }),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};