import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "height": "100%",
    "display": "flex",
    "flex-direction": "column",
    "justify-content": "space-between"
  }
};
var _hoisted_2 = {
  style: {
    "flex": "1"
  }
};
import { ref } from "vue";
import headVue from "./components/head.vue";
import footers from "./components/footer.vue";
export default {
  __name: 'App',
  setup: function setup(__props) {
    var fa = ref(111);
    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(headVue, {
        path: _ctx.$route.path
      }, null, 8, ["path"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view)]), _createVNode(footers)]);
    };
  }
};